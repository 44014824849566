import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Redirect } from "react-router";

import Login from "./screen/auth/Login";
import Dashboard from "./screen/admin/dashboard/Dashboard";
import Members from "./screen/admin/member/Members";
import AddMember from "./screen/admin/member/Addmember";
import Invite from "./screen/admin/member/Invite";
import SubscriptionPlan from "./screen/subscriptionplan/SubscriptionPlan";
import AddUnit from "./screen/config/Addunit";
import Addrole from "./screen/config/Addrole";
import { Provider } from "react-redux";
import store from "../src/redux/stores/Store";
import Addsubscriptionplan from "./screen/subscriptionplan/Addsubscriptionplan";
import Addtrainer from "./screen/trainer/Addtrainer";
import TrainerList from "./screen/trainer/TrainerList";
import StaffList from "./screen/staff/StaffList";
import Addstaff from "./screen/staff/Addstaff";
import Addgst from "./screen/config/Addgst";
import RoleList from "./screen/config/RoleList";
import UnitList from "./screen/config/UnitList";
import GstList from "./screen/config/GstList";
import TraineeList from "./screen/trainee/TraineeList";
import Addtrainee from "./screen/trainee/Addtrainee";
import AttendanceList from "./screen/attendance/AttendanceList";
import Addcheckin from "./screen/attendance/Addcheckin";
import AddRoster from "./screen/roster/AddRoster";
import RosterList from "./screen/roster/RosterList";
import Growth from "./screen/growth/Growth";
import Settings from "./screen/website/Settings";
import UserLogin from "./screen/UserLogin";
import BillingList from "./screen/billing/BillingList";
import CreateBill from "./screen/billing/CreateBill";
import Invoice from "./screen/billing/Invoice";
import ExerciseType from "./screen/config/ExerciseType";
import Addexercisetype from "./screen/config/Addexercisetype";
import Addexercise from "./screen/exerciselist/Addexercise";
import ExerciseList from "./screen/exerciselist/ExerciseList";
import VideoList from "./screen/video/VideoList";
import Addvideo from "./screen/video/Addvideo";
import Report from "./screen/report/Report";
import DietChart from "./screen/diet/DietChart";
import Adddietchart from "./screen/diet/Adddietchart";
import { AuthProvider } from "react-auth-kit";
import { RequireAuth } from "react-auth-kit";
import Updaterole from "./screen/config/Updaterole";
import Updateunit from "./screen/config/Updateunit";
import UpdateGST from "./screen/config/UpdateGST";
import Updateexercisetype from "./screen/config/Updateexercisetype";
import Updatestaff from "./screen/staff/Updatestaff";
import Updateexercise from "./screen/exerciselist/Updateexercise";
import Updatesubscriptionplan from "./screen/subscriptionplan/Updatesubscriptionplan";
import UpdateMember from "./screen/admin/member/Updatemember";
import Updatediet from "./screen/diet/UpdateDiet";
import UpdateVideo from "./screen/video/UpdateVideo";
import Revenue from "./screen/admin/dashboard/Revenue";
import Renewal from "./screen/admin/dashboard/Renewal";
import Profile from "./screen/admin/dashboard/Profile";
import Renewals from "./screen/admin/dashboard/Renewals";
import UpdateRoster from "./screen/roster/UpdateRoster";
import EnquiryList from "./screen/admin/enquiry/EnquiryList";
import AddEnquiry from "./screen/admin/enquiry/AddEnquiry";
import UpdateEnquiry from "./screen/admin/enquiry/UpdateEnquiry";
import MemberDetalisReport from "./screen/report/MemberDetailsReport";
import StaffDetailsReport from "./screen/report/StaffingDetalisReport";
import StaffingDetailsReport from "./screen/report/StaffingDetalisReport";
import GymSetting from "./screen/setting/GymSetting";
import AttendanceSetting from "./screen/setting/AttendanceSetting";
import BillingSetting from "./screen/setting/BillingSetting";
import BookingSetting from "./screen/setting/BookingSetting";
import EmailSetting from "./screen/setting/EmailSetting";
import MarketingSetting from "./screen/setting/MarketingSetting";
import MemberSetting from "./screen/setting/MemberSetting";
import SalesSetting from "./screen/setting/SalesSetting";
function App() {
  return (
    <AuthProvider
      authType={"cookie"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}
    >
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          {/* <Route exact path="/login" element={<Login />} /> */}
          {/* <Route
            exact
            path="/dashboard"
            element={
              <RequireAuth loginPath="/">
                <Dashboard />
              </RequireAuth>
            }
          /> */}
          <Route
            exact
            path="/profile/:id"
            element={
              <RequireAuth loginPath="/">
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/totalrevenue"
            element={
              <RequireAuth loginPath="/">
                <Revenue />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/renewals"
            element={
              <RequireAuth loginPath="/">
                <Renewals />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/renewals/:memberid/:subscriptionplanid"
            element={
              <RequireAuth loginPath="/">
                <Renewal />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/members/:newjoiner"
            element={
              <RequireAuth loginPath="/">
                <Members />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/addmember"
            element={
              <RequireAuth loginPath="/">
                <AddMember />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/invite"
            element={
              <RequireAuth loginPath="/">
                <Invite />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/subscriptionplan"
            element={
              <RequireAuth loginPath="/">
                <SubscriptionPlan />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/addsubscriptionplan"
            element={
              <RequireAuth loginPath="/">
                <Addsubscriptionplan />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/addunit"
            element={
              <RequireAuth loginPath="/">
                <AddUnit />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/addrole"
            element={
              <RequireAuth loginPath="/">
                <Addrole />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/rolelist"
            element={
              <RequireAuth loginPath="/">
                <RoleList />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/unitlist"
            element={
              <RequireAuth loginPath="/">
                <UnitList />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/addgst"
            element={
              <RequireAuth loginPath="/">
                <Addgst />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/gstlist"
            element={
              <RequireAuth loginPath="/">
                <GstList />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/stafflist"
            element={
              <RequireAuth loginPath="/">
                <StaffList />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/addstaff"
            element={
              <RequireAuth loginPath="/">
                <Addstaff />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/attendance"
            element={
              <RequireAuth loginPath="/">
                <AttendanceList />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/checkin"
            element={
              <RequireAuth loginPath="/">
                <Addcheckin />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/roster"
            element={
              <RequireAuth loginPath="/">
                <AddRoster />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/rosterlist"
            element={
              <RequireAuth loginPath="/">
                <RosterList />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/enquirylist"
            element={
              <RequireAuth loginPath="/">
                <EnquiryList />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/addenquiry"
            element={
              <RequireAuth loginPath="/">
                <AddEnquiry />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/updateenquiry/:id"
            element={
              <RequireAuth loginPath="/">
                <UpdateEnquiry />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/updateroster/:id"
            element={
              <RequireAuth loginPath="/">
                <UpdateRoster />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/growth"
            element={
              <RequireAuth loginPath="/">
                <Growth />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/settings"
            element={
              <RequireAuth loginPath="/">
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/memberdetailsreport"
            element={
              <RequireAuth loginPath="/">
                <MemberDetalisReport />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/staffdetailsreport"
            element={
              <RequireAuth loginPath="/">
                <StaffingDetailsReport />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/billinglist"
            element={
              <RequireAuth loginPath="/">
                <BillingList />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/createbill"
            element={
              <RequireAuth loginPath="/">
                <CreateBill />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/invoice/:billid"
            element={
              <RequireAuth loginPath="/">
                <Invoice />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/exercisetype"
            element={
              <RequireAuth loginPath="/">
                <ExerciseType />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/addexercisetype"
            element={
              <RequireAuth loginPath="/">
                <Addexercisetype />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/addexercise"
            element={
              <RequireAuth loginPath="/">
                <Addexercise />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/exerciselist"
            element={
              <RequireAuth loginPath="/">
                <ExerciseList />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/videolist"
            element={
              <RequireAuth loginPath="/">
                <VideoList />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/addvideo"
            element={
              <RequireAuth loginPath="/">
                <Addvideo />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/report"
            element={
              <RequireAuth loginPath="/">
                <Report />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/dietchart"
            element={
              <RequireAuth loginPath="/">
                <DietChart />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/adddietchart"
            element={
              <RequireAuth loginPath="/">
                <Adddietchart />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/updaterole/:id"
            element={
              <RequireAuth loginPath="/">
                <Updaterole />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/updateunit/:id"
            element={
              <RequireAuth loginPath="/">
                <Updateunit />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/updategst/:id"
            element={
              <RequireAuth loginPath="/">
                <UpdateGST />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/updateexercisetype/:id"
            element={
              <RequireAuth loginPath="/">
                <Updateexercisetype />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/updatestaff/:id"
            element={
              <RequireAuth loginPath="/">
                <Updatestaff />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/updateexercise/:id"
            element={
              <RequireAuth loginPath="/">
                <Updateexercise />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/updatesubscriptionplan/:id"
            element={
              <RequireAuth loginPath="/">
                <Updatesubscriptionplan />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/updatemember/:id"
            element={
              <RequireAuth loginPath="/">
                <UpdateMember />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/updatediet/:id"
            element={
              <RequireAuth loginPath="/">
                <Updatediet />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/updatevideo/:id"
            element={
              <RequireAuth loginPath="/">
                <UpdateVideo />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/gymsetting"
            element={
              <RequireAuth loginPath="/">
                <GymSetting />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/attendancesetting"
            element={
              <RequireAuth loginPath="/">
                <AttendanceSetting />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/emailsetting"
            element={
              <RequireAuth loginPath="/">
                <EmailSetting />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/membersetting"
            element={
              <RequireAuth loginPath="/">
                <MemberSetting />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/salessetting"
            element={
              <RequireAuth loginPath="/">
                <SalesSetting />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/bookingsetting"
            element={
              <RequireAuth loginPath="/">
                <BookingSetting />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/billingsetting"
            element={
              <RequireAuth loginPath="/">
                <BillingSetting />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/marketingsetting"
            element={
              <RequireAuth loginPath="/">
                <MarketingSetting />
              </RequireAuth>
            }
          />
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
